<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Add New Admin</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onSubmitUser">
                <div class="q-pa-md">
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Firstname"
                    lazy-rules
                     v-model="firstname"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Lastname"
                    lazy-rules
                    v-model="lastname"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Email"
                    lazy-rules
                    v-model="email"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Mobile"
                    lazy-rules
                    v-model="mobile"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                   type="password"
                    color="indigo-10"
                    outlined
                    label="Password"
                    lazy-rules
                    v-model="password"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    type="password"
                    color="indigo-10"
                    outlined
                    label="Confirm Password"
                    lazy-rules
                    v-model="confirm_password"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <br/>
                  <div>
                    <q-btn
                      style="background-color: #010127;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg"
                      clickable
                      :loading="isSubmitted"
                      type="submit"
                      >Create New User
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)

    const firstname = ref(null)
    const lastname = ref(null)
    const email = ref(null)
    const mobile = ref(null)
    const password = ref(null)
    const confirm_password = ref(null)

    EventBus.on("newAdminUser",(val) => {
        alert.value = val.status
    })


    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      firstname,
      lastname,
      email,
      mobile,
      password,
      confirm_password,

      onSubmitUser () {
        var formData = {
          "firstname":firstname.value.trim(),
          "lastname":lastname.value.trim(),
          "email":email.value.trim(),
          "mobile":mobile.value.trim(),
          "password":password.value.trim(),
          "password_confirmation":confirm_password.value.trim(),
          "role": 'admin',
        }
        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/register',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })

          firstname.value = '',
          lastname.value = '',
          email.value = '',
          mobile.value = '',
          password.value = '',
          confirm_password.value = '',

          EventBus.emit("refreshAdminUserData")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.error
            })
        })
      }

    }
  },

}
</script>