<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="dialog"
      persistent
      :maximized="maximizedToggle"
      transition-hide="zoom-out"
    >
    <q-card>
        <q-bar>
          <q-space />

          <q-btn dense flat icon="minimize" @click="maximizedToggle = false" :disable="!maximizedToggle">
            <q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="crop_square" @click="maximizedToggle = true" :disable="maximizedToggle">
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section class="q-pt-none">
           <iframe :src="pdfUrl"  style="position:fixed;left:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;"></iframe>
        </q-card-section>
      </q-card>
     
    </q-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import EventBus from "@/EventBus"

export default {

  setup () {

    const pdfUrl = ref(null)
    const dialog = ref(false)
    const maximizedToggle = ref(true)

    EventBus.on("pdfView",(val) => {
      dialog.value = val.status
      pdfUrl.value = val.url
    })

    return {
      pdfUrl,
      dialog,
      maximizedToggle,
    }
  }
}
</script>
