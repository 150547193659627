<template>
  <div className="q-gutter-sm q-pa-lg">
    <div className="row">
      <div className="col-lg-12 q-pa-md">
        <p className="text-h6 text-weight-bold">Question Bank
          <q-btn-group class="text-white float-right">
            <q-btn v-show="!loading" style="background-color: #010127;" label="Upload Question" icon="cloud_upload" @click="uploadQuestionModal"/>
            <q-btn v-show="!loading" style="background-color: #010127;" label="New Question" icon="add_circle" @click="addNewQuestion"/>
          </q-btn-group>
        </p>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
          <q-table
          :dense="$q.screen.lt.md"
          flat bordered
          ref="tableRef"
          title="Course Question Bank"
          :rows="rows"
          :columns="columns"
          row-key="id"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          binary-state-sort
          hide-header
          @request="onRequest">
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="bank_name" :props="props">
                <q-item class="q-my-sm">
                  <q-item-section>
                    <q-item-label>{{ props.row.bank_name  }}</q-item-label>
                    <q-item-label caption lines="1">{{ props.row.total_question  }}</q-item-label>
                    <q-item-label caption lines="1">{{ props.row.last_update  }}</q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <div class="q-pa-md q-gutter-sm">
                    <q-btn size="sm" outline round color="primary" icon="list" @click="selectedModuleQuestion(props.row)"/>
                    <q-btn size="sm" outline round color="amber" icon="edit" @click="editQuestionBank(props.row)"/>
                    </div>
                  </q-item-section>
                </q-item>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="filter" placeholder="Search">
            </q-input>
          </template>
          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center text-accent q-gutter-sm">
              <q-icon style="color: #010127;" size="2em" name="sentiment_dissatisfied" />
              <span style="color: #010127;">{{ message }}</span>
              <q-icon style="color: #010127;" size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div>
          </template>
        </q-table> 

      </div>

    </div>
    <UploadQuestion/>
    <AddNewQuestion/>
    <ModuleQuestions/>
    <UpdateQuestionBank/>
  </div>
</template>
<script>

import UploadQuestion from "./UploadQuestions.vue";
import AddNewQuestion from "./NewQuestion.vue";
import ModuleQuestions from "./ModuleQuestions.vue";
import UpdateQuestionBank from "./UpdateQuestionBank.vue";

import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"


const columns = [
  { name: 'bank_name', align: 'left', label: 'Group Name', field: 'bank_name', sortable: true },
  { name: 'total_question', align: 'left', label: 'Total Question', field: 'total_question', sortable: true },
  { name: 'last_update', align: 'left', label: 'Last Updated', field: 'last_update', sortable: true },
  { name: 'action', align: 'center', label: 'Action', field: ''},
]


export default {
  components: {
    UploadQuestion,
    AddNewQuestion,
    ModuleQuestions,
    UpdateQuestionBank
  },

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const tableRef = ref()
    const rows = ref([])
    const filter = ref('')
    const loading = ref(false)
    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10
    })

    EventBus.on("refreshQuestionBankData",() => tableRef.value.requestServerInteraction())

    const uploadQuestionModal = () => {
      EventBus.emit("uploadQuestionModal",{status:true})
    };

    const addNewQuestion = () => {
      EventBus.emit("addNewQuestion",{status:true})
    };

    const selectedModuleQuestion = (obj) => {
      EventBus.emit("selectedModuleQuestion",{status:true,obj:obj})
    };

    const editQuestionBank = (obj) => {
      EventBus.emit("editQuestionBank",{status:true,obj:obj})
    };


    async function fetchFromServer (startRow, count, filter) {
        let response =  await axios.get(process.env.VUE_APP_API_URL + '/question/bank', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
             }
         })
        return response.data
    }

    async function getRowsNumberCount  (filter)  {

        let response =  await axios.get(process.env.VUE_APP_API_URL + '/question/bank/total', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                filter: filter,
             }
         })

      return response.data
    }

    async function onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter
       loading.value = true

        await getRowsNumberCount(filter).then(
          function(value) { pagination.value.rowsNumber = value},
        )

        const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
        const startRow = (page - 1) * rowsPerPage

        await fetchFromServer(startRow, fetchCount, filter, sortBy, descending).then(
          function(value) { rows.value.splice(0, rows.value.length, ...value), loading.value = false},
        )

        pagination.value.page = page
        pagination.value.rowsPerPage = rowsPerPage
        pagination.value.sortBy = sortBy
        pagination.value.descending = descending
    }

    onMounted(() => {
      tableRef.value.requestServerInteraction()
    })

    return {
      token,
      tableRef,
      filter,
      loading,
      pagination,
      columns,
      rows,
      uploadQuestionModal,
      addNewQuestion,
      selectedModuleQuestion,
      editQuestionBank,
      onRequest,
    }
  }
}
</script>


<style lang="sass">
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px

  .row + .row
    margin-top: 1rem
</style>
