<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">New Question</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onSubmit">
                <div class="q-pa-md">
                  <q-input outlined v-model="bankName" label="Group Name" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
                  <q-input
                    autogrow
                    color="indigo-10"
                    outlined
                    label="Question"
                    lazy-rules
                    width="48"
                    v-model="question"
                    :rules="[ val => val && val.length > 0 || 'Please type questions']"
                  >
                    <template v-slot:append>
                      <q-icon name="help" />
                    </template>
                  </q-input>
                  <q-markup-table separator="none" flat>
                    <thead>
                      <tr>
                      <th class="text-left">Choices</th>
                      <th width="1%" class="text-center">Answer</th>
                      <th width="1%"  class="text-center"><q-btn outline size="sm"  style="color: #010127" label="Add" @click="addChoice"/></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item,index in questionChoices" :key="item">
                        <td class="text-left"><q-input outlined v-model="item.choice" label="Choice" lazy-rules :rules="[ val => val && val.length > 0 || 'Please type questions']"/></td>
                        <td class="text-center"><q-checkbox keep-color size="xl" v-model="item.answer"/></td>
                        <td class="text-center">
                          <q-btn round size="xs" icon="remove" color="negative" @click="removeChoice(index)"/>
                        </td>
                      </tr>
                    </tbody>
                  </q-markup-table>
                  <q-btn
                    style="background-color: #010127;"
                    color="white-8"
                    rounded
                    class="full-width q-mr-sm"
                    v-ripple
                    clickable
                    size="lg"
                    :loading="isSubmitted"
                    type="submit"
                    >Save Question
                  </q-btn>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>

import { useQuasar } from 'quasar'  
import { ref,reactive } from 'vue'
import EventBus from "@/EventBus"
import axios from 'axios'

export default {

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const isSubmitted = ref(false)
    const alert = ref(false)
    const bankName = ref(null)
    const question = ref(null)
    const questionChoices = reactive([{choice:'', answer:false}])
    const addChoice = () => {questionChoices.push({choice:'', answer:false})};
    const removeChoice = (index) => {
      if(questionChoices.length > 1){
        questionChoices.splice(index,1)
      }
    }

     EventBus.on("addNewQuestion",(val) => {
        alert.value = val.status
    })

    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      bankName,
      question,
      questionChoices,
      addChoice,
      removeChoice,

      onSubmit () {
        var formData = {
          "bank_name":bankName.value.trim(),
          "question":question.value.trim(),
          "choices":questionChoices,
        }

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/question',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
            EventBus.emit("refreshQuesitonBankData")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }
    }

  },
}
</script>