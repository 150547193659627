<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Choices</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
          <div class="q-pa-md q-gutter-md">
            <q-list>
              <q-item clickable v-ripple v-for="item,index in choices" :key="item">
                <q-item-section avatar>
                  <q-avatar style="background-color: #010127;" text-color="white">
                    {{ index+1 }}
                  </q-avatar>
                </q-item-section>

                <q-item-section>
                  <q-item-label class="text-body1">{{ item.choice }}</q-item-label>
                  <q-item-label caption lines="1">{{ item.answer ? 'Correct Answer' : '' }}</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <q-icon v-show="item.answer" name="check_circle" color="positive" />
                </q-item-section>
              </q-item>
            </q-list>
          </div>
      </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>

import { ref } from 'vue'
import EventBus from "@/EventBus"

export default {

  setup () {
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const choices = ref([])

    EventBus.on("selectedRowChoices",(val) => {
        alert.value = val.status
        choices.value = val.choices
    })

    return {
      alert,
      backdropFilter,
      choices
    }

  },
}
</script>