import { createRouter, createWebHashHistory } from "vue-router";
import vueCookies from 'vue-cookies';

import LoginView from "@/views/LoginView.vue";
import NavbarView from "../views/NavbarView.vue";
import DashboardView from "../components/dashboard/DashboardView.vue";
import CourseView from "../components/courses/CourseView.vue";
import CollectionsView from "../components/collections/CollectionsView.vue";
import TopicsView from "../components/topics/TopicsView.vue";

import AssessmentsVeiw from "../components/assessments/AssessmentsView.vue";
import AccessCodeView from "../components/AccessCodeView.vue";
import AccountsView from "../components/accounts/AccountsView.vue";

import QuestionBankView from "../components/questionbank/QuestionBankView.vue";

import UsersView from "../components/users/UsersView.vue";
import VideosView from "../components/videos/VideosView.vue";
import ExamResults from "../components/exams/ExamResults.vue";
import CourseDetails from "../components/courses/CourseDetails.vue";

const routes = [
  {
    meta: { title: 'Login' },
    path: "/",
    name: "login",
    component: LoginView,
  },
  {
    meta: { title: 'Dashboard' },
    path: "/",
    name: "dashboard",
    component: NavbarView,
    children: [
      { meta: { title: 'Dashboard' },path: "dashboard", name: "dashboard", component: DashboardView },
      { meta: { title: 'Courses' },path: "courses", name: "courses", component: CourseView },
      { meta: { title: 'Collections' },path: "collections", name: "collections", component: CollectionsView },
      { meta: { title: 'Topics' },path: "topics", name: "topics", component: TopicsView },
      { meta: { title: 'Videos' },path: "videos", name: "videos", component: VideosView },
      { meta: { title: 'Assessments' },path: "assessments", name: "assessments", component: AssessmentsVeiw },
      { meta: { title: 'Question Bank' },path: "question-bank", name: "questionbank", component: QuestionBankView },
      { meta: { title: 'Accounts' },path: "accounts", name: "accounts", component: AccountsView },
      { meta: { title: 'Access Code' },path: "access-code", name: "access-code", component: AccessCodeView },
      { meta: { title: 'User' },path: "users", name: "users", component: UsersView },
      { meta: { title: 'Exam Results' },path: "exam-results", name: "exam", component: ExamResults },

      { meta: { title: 'Course Details' },path: "course/details/:id", name: "course-details", component: CourseDetails },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  
  let cookie = vueCookies.get(process.env.VUE_APP_API_KEY);

  if(cookie == null && to.name != 'login') {
      router.push('/');
  } else if(cookie != null && to.name == 'login') {    
      router.push('/dashboard');
  }else{
      document.title = to.meta.title
      next();
  }
})

export default router;
