<template>
  <div class="q-gutter-sm q-pa-lg">
    <div className="row">
      <div className="col-lg-12">
        <p className="text-h6 text-weight-bold">Manage Assessments
          <q-btn v-show="!loading" style="background-color: #010127;" class="text-white float-right" icon="add_circle" label="New Assessment" @click="addAssessmentDialog(courseId)"/>
        </p>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <q-table
          :dense="$q.screen.lt.md"
          flat bordered
          ref="tableRef"
          title="Assessments"
          :rows="rows"
          :columns="columns"
          row-key="assessmentid"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          binary-state-sort
          @request="onRequest">
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="topic" :props="props">{{ props.row.topic }}</q-td>
              <q-td key="name" :props="props">{{ props.row.name }}</q-td>
              <q-td key="description" :props="props">{{ props.row.description }}</q-td>
              <q-td key="duration" :props="props">{{ props.row.duration }}</q-td>
              <q-td key="total_items" :props="props">{{ props.row.total_items }}</q-td>
              <q-td key="difficulty" :props="props">{{ props.row.difficulty }}</q-td>
              <q-td key="published" :props="props">
              <q-chip outline :color="props.row.published == 1 ? 'green' : 'grey'" text-color="white" :icon="props.row.published == 1 ? 'check_circle' : 'schedule'">
                {{ props.row.published == 1 ? 'Published' : 'Unpublished' }}
              </q-chip>
              </q-td>
              <q-td key="closed_on" :props="props">{{ props.row.closed_on }}</q-td>
              <q-td key="date_upload" :props="props">{{ props.row.date_upload  }}</q-td>
              <q-td key="action" :props="props">
                <q-btn size="sm" round outline color="positive" @click="addQuestionDialog(props.row)">
                    <i class="fas fa-question"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Add Questions</strong>
                    </q-tooltip>
                </q-btn>
                &nbsp;
                <q-btn size="sm" round outline color="warning" @click="updateAssessmentModal(props.row)">
                    <i class="fas fa-edit"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Edit</strong>
                    </q-tooltip>
                </q-btn>
                &nbsp;
                <q-btn size="sm" round outline color="negative" @click="deleteAssessmentModal(props.row.id)">
                    <i class="fas fa-trash"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Inactive</strong>
                    </q-tooltip>
                </q-btn>
                &nbsp;
                <q-btn size="sm" round outline color="teal" @click="publishedAssessmentModal(props.row.id,props.row.total_items)">
                    <i class="fas fa-upload"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Publish</strong>
                    </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="filter" placeholder="Search">
            </q-input>
          </template>
          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center text-accent q-gutter-sm">
              <q-icon style="color: #010127;" size="2em" name="sentiment_dissatisfied" />
              <span style="color: #010127;">{{ message }}</span>
              <q-icon style="color: #010127;" size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div>
          </template>
        </q-table>
      </div>
    </div>
    <AddNewAssessment/>
    <UpdateAssessment/>
    <DeleteAssessment/>
    <PublishedAssessment/>
    <AddQuestions/>
  </div>
</template>

<script>
import AddNewAssessment from "./AddNewAssessment.vue";
import UpdateAssessment from "./UpdateAssessment.vue";
import DeleteAssessment from "./DeleteAssessment.vue";
import PublishedAssessment from "./PublishedAssessment.vue";
import AddQuestions from "./AddQuestions.vue";

import { ref, onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

const columns = [
  { name: 'topic', align: 'left', label: 'Topic', field: 'topic', sortable: true },
  { name: 'name', align: 'left', label: 'Name', field: 'name', sortable: true },
  { name: 'description', align: 'left',label: 'Description', field: 'description', sortable: true },
  { name: 'duration', align: 'left', label: 'Duration', field: 'duration', sortable: true },
  { name: 'total_items', align: 'left', label: 'Total Items', field: 'total_items', sortable: true },
  { name: 'difficulty', align: 'left', label: 'Difficulty', field: 'difficulty', sortable: true },
  { name: 'published', align: 'left', label: 'Pubished', field: 'published', sortable: true },
  { name: 'closed_on', align: 'left', label: 'Closed On', field: 'closed_on', sortable: true },
  { name: 'date_upload', align: 'left', label: 'Date Upload', field: 'date_upload', sortable: true },
  { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
]


export default {
  components: {
    AddNewAssessment,
    UpdateAssessment,
    DeleteAssessment,
    PublishedAssessment,
    AddQuestions
  },

  props:{
    courseId:{
      type:String,
      required:true
    }
  },

  setup (props) {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const tableRef = ref()
    const rows = ref([])
    const filter = ref('')
    const loading = ref(false)
    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10
    })

    EventBus.on("refreshAssessment",() => tableRef.value.requestServerInteraction())

    const addAssessmentDialog = (courseId) => {
      EventBus.emit("addAssessmentDialog",{status:true,courseId:courseId})
    };

    const updateAssessmentModal = (obj) => {
      EventBus.emit("updateAssessmentModal",{status:true,obj:obj})
    };

    const deleteAssessmentModal = (id) => {
      EventBus.emit("deleteAssessmentModal",{status:true,id:id})
    };

    const publishedAssessmentModal = (id,totalItem) => {
      EventBus.emit("publishedAssessmentModal",{status:true,id:id,total:totalItem})
    };

    const addQuestionDialog = (obj) => {
      EventBus.emit("addQuestionDialog",{status:true,courseId:props.courseId,obj:obj})
    };

    async function fetchFromServer (startRow, count, filter) {
        let response =  await axios.get(process.env.VUE_APP_API_URL + '/assessment', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                courseId:props.courseId,
                start: startRow,
                countPerPage: count,
                filter: filter,
             }
         })
        return response.data
    }

    async function getRowsNumberCount  (filter)  {

        let response =  await axios.get(process.env.VUE_APP_API_URL + '/assessment/total', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                courseId:props.courseId,
                filter: filter,
             }
         })

      return response.data
    }

    async function onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter
       loading.value = true

        await getRowsNumberCount(filter).then(
          function(value) { pagination.value.rowsNumber = value},
        )

        const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
        const startRow = (page - 1) * rowsPerPage

        await fetchFromServer(startRow, fetchCount, filter, sortBy, descending).then(
          function(value) { rows.value.splice(0, rows.value.length, ...value), loading.value = false},
        )

        pagination.value.page = page
        pagination.value.rowsPerPage = rowsPerPage
        pagination.value.sortBy = sortBy
        pagination.value.descending = descending
    }

    onMounted(() => {
      tableRef.value.requestServerInteraction()
    })

    return {
      token,
      tableRef,
      filter,
      loading,
      pagination,
      columns,
      rows,
      addAssessmentDialog,
      updateAssessmentModal,
      deleteAssessmentModal,
      publishedAssessmentModal,
      addQuestionDialog,
      onRequest
    }
  }
}
</script>

<style lang="sass">
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px

  .row + .row
    margin-top: 1rem
</style>
