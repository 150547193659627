
import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'

import Notify from 'quasar/src/plugins/notify/Notify.js';import Loading from 'quasar/src/plugins/loading/Loading.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,Loading
  },
  iconSet: iconSet
}