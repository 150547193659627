<template>
  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Assign Course</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
      <q-card-section class="row items-center">
      <q-markup-table separator="none" flat>
          <thead>
            <tr>
              <th class="text-left">Course</th>
              <th class="text-right">Assign</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in coursesList" :key="item">
              <td class="text-left">{{ item.name }}</td>
              <td class="text-right"><q-checkbox v-model="selectedCourse" :val="item.id" keep-color/></td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="warning" v-close-popup />
        <q-btn flat :loading="isSubmitted" label="Update" color="positive" @click="confirmAssign" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar' 
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"
export default {
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const confirm = ref(false)
    const coursesList = ref([])
    const selectedCourse = ref([])
    const assignedCourse = ref([])
    const id = ref(null)
    const isSubmitted = ref(false)

    function getCoursesList () {
        axios.get(process.env.VUE_APP_API_URL + '/course/dropdown',{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((res) => {
          coursesList.value = res.data
        })
    }


    EventBus.on("assignModal",(val) => {
        id.value = val.obj.id
        confirm.value = val.status
        selectedCourse.value = val.obj.courses
    })

    const confirmAssign = () => {

      var formData = {
          "id":id.value,
          "courses":selectedCourse.value,
        }

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/course/assign',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
     
          isSubmitted.value = false
          confirm.value = false
          $q.notify({
            color: 'positive',
            textColor: 'white',
            icon: 'check_circle',
            message: response.data.message
          })
          EventBus.emit("refreshData")
      
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
    }

    onMounted(() => {
      getCoursesList()
    })

    return {
      token,
      confirm,
      id,
      coursesList,
      selectedCourse,
      assignedCourse,
      isSubmitted,
      confirmAssign,
    }
  }
}
</script>