<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Add New Video</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onSubmitVideo">
                <div class="q-pa-md">
                  <q-select outlined v-model="moduleId" :loading="fetchingDropdown" :options="moduleList" option-value="id" option-label="name" emit-value map-options label="Module" lazy-rules :rules="[ val => val && val.length > 0 || 'Please select options']"/>
                  <q-input
                    color="indigo-10" outlined label="Title" lazy-rules v-model="title" :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Description"
                    lazy-rules
                    v-model="description"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-file accept="video/mp4" color="indigo-10" outlined label="Attached File" lazy-rules v-model="file">
                    <template v-slot:prepend>
                      <q-icon name="attach_file" />
                    </template>
                  </q-file>
                  <br/>
                  <div>
                    <q-btn
                      style="background-color: #010127;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg"
                      clickable
                      :loading="isSubmitted"
                      type="submit"
                      >Save Video
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)
    const fetchingDropdown = ref(false)
    const moduleList = ref([])
    const courseId = ref(null)
    const moduleId = ref(null)
    const title = ref(null)
    const description = ref(null)
    const file = ref(null)

    EventBus.on("openVideoModal",(val) => {
        alert.value = val.status
        courseId.value = val.courseId
        getModuleList(val.courseId)
    })

    function getModuleList (courseId) {
        fetchingDropdown.value = true
        axios.get(process.env.VUE_APP_API_URL + '/module/dropdown',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {courseId: courseId}
        }).then((res) => {
          moduleList.value = res.data
          fetchingDropdown.value = false
        })
    }

    return {
      token,
      alert,
      backdropFilter,
      fetchingDropdown,
      isSubmitted,
      courseId,
      moduleList,
      moduleId,
      title,
      description,
      file,

      onSubmitVideo () {

        let formData = new FormData();
        formData.append("courseId", courseId.value.trim());
        formData.append("moduleId", moduleId.value.trim());
        formData.append("title", title.value.trim());
        formData.append("description", description.value.trim());
        formData.append("file", file.value);

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/video/upload',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false

            moduleId.value = ''
            title.value = '';
            description.value = '';
            file.value = '';
            
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          EventBus.emit("refreshVideo")
          }else{
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }

    }
  },
}
</script>