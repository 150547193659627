<template>
  <div className="q-pa-md example-row-all-breakpoints">
    <div className="row">
      <div className="col-lg-12">
        <p className="text-h6 text-weight-bold">Exam Results</p>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12">
        <q-table
          :dense="$q.screen.lt.md"
          flat bordered
          ref="tableRef"
          title="Exam Results"
          :rows="rows"
          :columns="columns"
          row-key="id"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          binary-state-sort
          @request="onRequest">
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="firstname" :props="props">{{ props.row.firstname }}</q-td>
              <q-td key="lastname" :props="props">{{ props.row.lastname }}</q-td>
              <q-td key="assessment" :props="props">{{ props.row.assessment }}</q-td>
              <q-td key="time_consume" :props="props">{{ props.row.time_consume }}</q-td>
              <q-td key="score" :props="props">{{ props.row.score }}</q-td>
              <q-td key="average" :props="props">{{ props.row.average }}</q-td>
              <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
              <q-td key="action" :props="props">
                <q-btn size="sm" round outline color="warning" @click="openModal(props.row)">
                    <i class="fas fa-list"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>View Result</strong>
                    </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="filter" placeholder="Search">
            </q-input>
          </template>
          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center text-accent q-gutter-sm">
              <q-icon style="color: #010127;" size="2em" name="sentiment_dissatisfied" />
              <span style="color: #010127;">{{ message }}</span>
              <q-icon style="color: #010127;" size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div>
          </template>
        </q-table>
      </div>
    </div>
    <ViewExam/>
  </div>
</template>

<script>
import ViewExam from "./ViewExam.vue";
import { ref, onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

const columns = [
  { name: 'firstname', align: 'left', label: 'Firstname', field: 'firstname', sortable: true },
  { name: 'lastname', align: 'left', label: 'Lastname', field: 'lastname', sortable: true },
  { name: 'assessment', align: 'left',label: 'Assessment', field: 'assessment', sortable: true },
  { name: 'time_consume', align: 'left', label: 'Time Consume', field: 'time_consume', sortable: true },
  { name: 'score', align: 'left', label: 'Score', field: 'score', sortable: true },
  { name: 'average', align: 'left', label: 'Average', field: 'average', sortable: true },
  { name: 'date_created', align: 'left', label: 'Date Taken', field: 'date_created', sortable: true },
  { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
]


export default {
  components: {
    ViewExam
  },

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const tableRef = ref()
    const rows = ref([])
    const filter = ref('')
    const loading = ref(false)
    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10
    })

    const openModal = (obj) => {
      EventBus.emit("openDialog",{status:true,obj:obj})
    }

    async function fetchFromServer (startRow, count, filter) {
        let response =  await axios.get(process.env.VUE_APP_API_URL + '/exam', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
             }
         })
        return response.data
    }

    async function getRowsNumberCount  (filter)  {

        let response =  await axios.get(process.env.VUE_APP_API_URL + '/exam/total', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                filter: filter,
             }
         })

      return response.data
    }

    async function onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter
       loading.value = true

        await getRowsNumberCount(filter).then(
          function(value) { pagination.value.rowsNumber = value},
        )

        const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
        const startRow = (page - 1) * rowsPerPage

        await fetchFromServer(startRow, fetchCount, filter, sortBy, descending).then(
          function(value) { rows.value.splice(0, rows.value.length, ...value), loading.value = false},
        )

        pagination.value.page = page
        pagination.value.rowsPerPage = rowsPerPage
        pagination.value.sortBy = sortBy
        pagination.value.descending = descending
    }

    onMounted(() => {
      tableRef.value.requestServerInteraction()
    })

    return {
      token,
      tableRef,
      filter,
      loading,
      pagination,
      columns,
      rows,
      openModal,
      onRequest
    }
  }
}
</script>

<style lang="sass">
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px

  .row + .row
    margin-top: 1rem
</style>
