<template>
  <div>
    <div className="col-lg-12">
      <p className="text-h6 text-weight-bold">Manage Module
        <q-btn v-show="!fetchingData" style="background-color: #010127;" class="text-white float-right" icon="add_circle" label="Create Module" @click="newModuleModal(courseId)"/>
      </p>
    </div>
    <div v-show="modulesData.length == 0 && !fetchingData">
        <p class="text-body1 absolute-center">No Module Available</p>
    </div>
    <div class="q-pa-md" v-show="modulesData.length > 0 && !fetchingData">
        <q-list bordered separator>
          <q-expansion-item
            v-for="data in modulesData" :key="data.id" 
            expand-icon-toggle
            switch-toggle-side
            expand-separator>
            <template v-slot:header>
              <q-item-section avatar top>
                <q-avatar style="background-color: #010127;" text-color="white">
                    {{ data.sequence }}
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ data.name }}</q-item-label>
                <q-item-label caption lines="2">{{ data.description }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <div class="row items-center">
                  <q-btn flat round color="primary" icon="edit" @click="editModuleModal(data)"/>
                </div>
              </q-item-section>
            </template>
            <q-separator inset="item" />

            <q-expansion-item
              v-show="data.topic.length > 0"
              :header-inset-level="1"
              :content-inset-level="2"
              expand-separator>
              <template v-slot:header>
                <q-item-section avatar>
                  <q-icon color="primary" name="topic" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Topics</q-item-label>
                  <q-item-label caption lines="2">{{ data.topic.length }} total</q-item-label>
                </q-item-section>
              </template>
              <q-list separator >
                <q-item clickable v-for="topic in data.topic" :key="topic.id">
                  <q-item-section avatar>
                    <q-icon color="secondary" name="topic" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ topic.name }}</q-item-label>
                    <q-item-label caption>{{ topic.description }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
            <q-separator inset="item"/>
            <q-expansion-item
              v-show="data.video.length > 0"
              :header-inset-level="1"
              :content-inset-level="2"
              expand-separator>
              <template v-slot:header>
                <q-item-section avatar>
                  <q-icon color="primary" name="video_camera_front" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Video</q-item-label>
                  <q-item-label caption lines="2">{{ data.video.length }} total</q-item-label>
                </q-item-section>
              </template>
              <q-list separator >
                <q-item clickable v-for="video in data.video" :key="video.id">
                  <q-item-section avatar>
                    <q-icon color="secondary"  name="video_camera_front" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ video.title }}</q-item-label>
                    <q-item-label caption>{{ video.description }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
            <q-separator inset="item"/>
            <q-expansion-item
              v-show="data.note.length > 0"
              :header-inset-level="1"
              :content-inset-level="2"
              expand-separator>
              <template v-slot:header>
                <q-item-section avatar>
                  <q-icon color="primary" name="sticky_note_2" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Notes</q-item-label>
                  <q-item-label caption lines="2">{{ data.note.length }} total</q-item-label>
                </q-item-section>
              </template>
              <q-list separator >
                <q-item clickable v-for="note in data.note" :key="note.id">
                  <q-item-section avatar>
                    <q-icon color="secondary" name="sticky_note_2" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ note.title }}</q-item-label>
                    <q-item-label caption>{{ note.description }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
            <q-separator inset="item"/>
            <q-expansion-item
              v-show="data.assessment.length > 0"
              :header-inset-level="1"
              :content-inset-level="2"
              expand-separator>
              <template v-slot:header>
                <q-item-section avatar>
                  <q-icon color="primary" name="analytics" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Assessment</q-item-label>
                  <q-item-label caption lines="2">{{ data.assessment.length }} total</q-item-label>
                </q-item-section>
              </template>
              <q-list separator >
                <q-item clickable v-for="assessment in data.assessment" :key="assessment.id">
                  <q-item-section avatar>
                    <q-icon color="secondary" name="analytics" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>{{ assessment.title }}</q-item-label>
                    <q-item-label caption>{{ assessment.description }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>

          </q-expansion-item>
        </q-list>
      </div>
      <div class="q-pa-lg flex flex-center" v-show="!fetchingData">
        <span>Showing {{ showingFrom }} to {{ showingTo }} of {{ totalEntries }} entries</span>  
        <q-space />
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="keyboard_double_arrow_left" @click="selectPagination(firstPageUrl)"/>
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="arrow_back_ios" @click="selectPagination(prevPageUrl)"/>
        <span>{{ firstPage }} / {{ lastPage }}</span>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="arrow_forward_ios" @click="selectPagination(nextPageUrl)"/>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="keyboard_double_arrow_right" @click="selectPagination(lastPageUrl)"/>
      </div>
      <AddNewModule/>
      <EditModule/>
  </div>
</template>

<script>
import AddNewModule from "./AddNewModule.vue";
import EditModule from "./EditModule.vue";
import { useQuasar, QSpinnerPie } from 'quasar'
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"


export default {
  components: {
    AddNewModule,
    EditModule
  },

  props:{
    courseId:{
      type:String,
      required:true
    }
  },

  setup (props) {
    const $q = useQuasar()
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const fetchingData = ref(false)
    const splitterModel = ref(50)
    const selected = ref('Topics')
    const modulesData = ref([])

    const showingFrom = ref(0)
    const showingTo = ref(0)
    const totalEntries = ref(0)

    const firstPage = ref(0)
    const lastPage = ref(0)

    const nextPageUrl = ref(null)
    const prevPageUrl = ref(null)

    const firstPageUrl = ref(null)
    const lastPageUrl = ref(null)
  
    EventBus.on("refreshModuleData",() => getModules())

    const newModuleModal = (courseId) => {
      EventBus.emit("newModuleModal",{status:true,courseId:courseId})
    }

    const editModuleModal = (obj) => {
      EventBus.emit("editModuleModal",{status:true,obj:obj})
    }


    const selectPagination = (urls) => {
      const urlString = urls 
      const url = new URL(urlString);
      const params = new URLSearchParams(url.search);
      const page = params.get('page'); 
      getModules(page)

    };

    function getModules(page)  {
        fetchingData.value = true
        $q.loading.show({
          spinner: QSpinnerPie,
          spinnerColor: 'red',
          messageColor: 'black',
          backgroundColor: 'grey',
          message: 'Fetching Records.<br><span class="text-italic">Please wait...</span>',
          html: true
        })
        axios.get(process.env.VUE_APP_API_URL + '/module',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
              course_id:props.courseId,
              page: page,
           }
        }).then((res) => {
          firstPage.value = res.data.current_page
          lastPage.value = res.data.last_page

          showingFrom.value = res.data.from
          showingTo.value = res.data.to
          totalEntries.value = res.data.total

          nextPageUrl.value = res.data.next_page_url
          prevPageUrl.value = res.data.prev_page_url

          firstPageUrl.value = res.data.first_page_url
          lastPageUrl.value = res.data.last_page_url

          modulesData.value = res.data.data

          fetchingData.value = false
          $q.loading.hide()
        })
    }

    onMounted(() => {
      getModules()
    })

    return {
      newModuleModal,
      editModuleModal,
      fetchingData,
      splitterModel,
      selected,
      modulesData,

      showingFrom,
      showingTo,
      totalEntries,
      firstPageUrl,
      firstPage,
      nextPageUrl,
      prevPageUrl,
      lastPage,
      lastPageUrl,

      selectPagination
    }
  }
}
</script>
