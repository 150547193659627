<template>
  <q-dialog v-model="confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="delete" color="negative" text-color="white" />
        <span class="q-ml-sm">Do you want to delete this record?.</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="warning" v-close-popup />
        <q-btn flat :loading="isSubmitted" label="Confirm" color="positive" @click="confirmDelete" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar' 
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"
export default {
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const confirm = ref(false)
    const id = ref(null)
    const isSubmitted = ref(false)

    EventBus.on("deleteModal",(val) => {
        id.value = val.id
        confirm.value = val.status
    })

    const confirmDelete = () => {
      isSubmitted.value = true; 
      axios.delete(process.env.VUE_APP_API_URL + '/collection/'+ id.value,{
        headers: { 'Authorization': `Bearer ${token.value}`},
      }).then(() => {
          isSubmitted.value = false
          confirm.value = false
          $q.notify({
            color: 'positive',
            textColor: 'white',
            icon: 'check_circle',
            message: 'Record Deleted'
          })
          location.reload()
        }).catch((e) => {
         isSubmitted.value = false
         $q.notify({
            color: 'negative',
            textColor: 'white',
            icon: 'warning',
            message: e.response.data.message
          })
      })
    }

    return {
      token,
      confirm,
      id,
      isSubmitted,
      confirmDelete
    }
  }
}
</script>