<template>
  <q-layout view="lHh Lpr lFf" class="bg-grey-4">
    <q-header elevated class="text-white-8 q-py-xs" height-hint="58" style="background: #010127;">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
        />
        <q-space />

        <div class="q-gutter-sm row items-center no-wrap">

          <q-btn outline icon="auto_stories" label="Create Course" @click="createCourse()">
            <q-tooltip>Create Course</q-tooltip>
          </q-btn>

          <q-btn round flat>
            <q-avatar size="26px">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
            </q-avatar>
            <q-tooltip>Account</q-tooltip>
            <q-menu transition-show="jump-down" transition-hide="jump-up">
              <q-list style="min-width: 230px">
                <q-item clickable>
                  <q-item-section avatar>
                    <q-icon name="settings" />
                  </q-item-section>
                  <q-item-section>Settings</q-item-section>
                </q-item>
                <q-item clickable>
                  <q-item-section avatar>
                    <q-icon name="person" />
                  </q-item-section>
                  <q-item-section>Profile</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable @click="logoutDialog()">
                  <q-item-section>
                    <q-btn size="md" color="negative" icon-right="logout" label="Logout"/>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      elevated
      class="bg-grey-2"
    >
      <q-scroll-area style="height: calc(100% - 150px); margin-top: 150px">
        <q-list padding>
          <q-item-label header class="text-weight-bold text-uppercase">
            INSIGHT
          </q-item-label>
          <q-item
            v-for="link in links1"
            :key="link.text"
            :to="link.link"
            v-ripple
            clickable
            :class="this.$route.name == link.name ? 'active-menu' : ''"
            class="menu"
          >
            <q-item-section avatar>
              <q-icon
                :color="this.$route.name == link.name ? 'white' : 'grey'"
                :name="link.icon"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            v-for="link in links3"
            :key="link.text"
            :to="link.link"
            v-ripple
            clickable
            :class="this.$route.name == link.name ? 'active-menu' : ''"
            class="menu"
          >
            <q-item-section avatar>
              <q-icon
                :color="this.$route.name == link.name ? 'white' : 'grey'"
                :name="link.icon"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-separator class="q-mt-md q-mb-lg" />
          <q-item-label header class="text-weight-bold text-uppercase">
            MANAGEMENT
          </q-item-label>
          <q-item
            v-for="link in links5"
            :key="link.text"
            :to="link.link"
            v-ripple
            clickable
            :class="this.$route.name == link.name ? 'active-menu' : ''"
          >
            <q-item-section avatar>
              <q-icon
                :color="this.$route.name == link.name ? 'white' : 'grey'"
                :name="link.icon"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>

      <q-img
        class="absolute-top"
        src="https://images.unsplash.com/photo-1511149521648-612cb6799f07?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        style="height: 150px"
      >
        <div class="absolute-bottom bg-transparent">
          <q-avatar size="56px" class="q-mb-sm">
            <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
          </q-avatar>
          <div class="text-weight-bold">{{ token.firstname }} {{ token.lastname }}</div>
          <div>{{ token.email }}</div>
        </div>
      </q-img>
    </q-drawer>

    <q-page-container>
      <router-view />
      <LogoutView></LogoutView>
      <CreateCourse></CreateCourse>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import { fabYoutube } from "@quasar/extras/fontawesome-v6";
import LogoutView from "./LogoutView.vue";
import CreateCourse from "../components/courses/CreateCourse.vue";
import EventBus from "@/EventBus"

export default {
  components: {
    LogoutView,
    CreateCourse
  },

  setup() {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY))
    const leftDrawerOpen = ref(false);
    const search = ref("");

    function toggleLeftDrawer() {
      leftDrawerOpen.value = !leftDrawerOpen.value;
    }

    const createCourse = () => {
      EventBus.emit("createCourse",{status:true})
    };

    const logoutDialog = () => {
      EventBus.emit("logoutDialog",{status:true})
    };

    return {
      token,
      fabYoutube,
      createCourse,
      logoutDialog,
      leftDrawerOpen,
      search,

      toggleLeftDrawer,

      links1: [
        {
          icon: "home",
          text: "Dashboard",
          link: "/dashboard",
          name: "dashboard",
        },
      ],
      links3: [
        {
          icon: "storage",
          text: "Question Bank",
          link: "/question-bank",
          name: "questionbank",
        },
         {
          icon: "workspace_premium",
          text: "Exam Results",
          link: "/exam-results",
          name: "exam",
        },
      ],
      links4: [
        {
          icon: "group",
          text: "Accounts",
          link: "/accounts",
          name: "accounts",
        },
      ],
      links5: [
        {
          icon: "account_circle",
          text: "Users",
          link: "/users",
          name: "users",
        },
        {
          icon: "group",
          text: "Accounts",
          link: "/accounts",
          name: "accounts",
        },
      ],
    };
  },
};
</script>

<style lang="sass">
.YL

  &__toolbar-input-container
    min-width: 100px
    width: 55%

  &__toolbar-input-btn
    border-style: solid
    border-width: 1px 1px 1px 0
    border-color: rgba(0,0,0,.24)
    max-width: 60px
    width: 100%

  &__drawer-footer-link
    color: inherit
    text-decoration: none
    font-weight: 500
    font-size: .75rem

    &:hover
      color: #000

a.menu:hover
  opacity: 0.7
  color: white
  background-color: #010127

.active-menu
  color: white !important
  background-color: #010127
  padding: 2px 7px 2px 7px
  &:hover
    opacity: 1
    color:  black

.active
  color: white !important
  padding: 2px 7px 2px 7px
  border-bottom: white 2px solid
  &:hover
    opacity: 1
    color:  white
</style>
