<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">New Assessment</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onSubmitAssessment">
                <div class="q-pa-md">
                  <q-select outlined v-model="moduleId" :loading="fetchingDropdown" :options="moduleList" option-value="id" option-label="name" emit-value map-options label="Module" lazy-rules :rules="[ val => val && val.length > 0 || 'Please select options']" @update:modelValue="getTopicList()"/>
                  <q-select outlined v-model="topic" :loading="fetchingModule" :options="topicList" option-value="id" option-label="name" emit-value map-options label="Topic" lazy-rules :rules="[ val => val && val.length > 0 || 'Please select options']"/>
                  <q-input color="indigo-10" outlined label="Assessment" lazy-rules v-model="name" :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Description"
                    lazy-rules
                    v-model="description"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input type="number" min="1" color="indigo-10" outlined label="Duration in minutes" lazy-rules v-model="duration" :rules="[ val => val && val.length > 0 || 'Please type something']"/>
                  <q-input type="number" min="1" color="indigo-10" outlined label="Total Items" lazy-rules v-model="total_items" :rules="[ val => val && val.length > 0 || 'Please type something']"/>
                  <q-select outlined v-model="difficulty" :options="difficultyOptions" label="Level of Difficulty" />
                  <br/>
                  <div>
                    <q-btn
                      style="background-color: #010127;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg"
                      clickable
                      :loading="isSubmitted"
                      type="submit"
                      >Save Assessment
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)
    const fetchingDropdown = ref(false)
    const fetchingModule = ref(false)
    const moduleList = ref([])
    const courseId = ref(null)
    const moduleId = ref(null)
    const topicList = ref([])
    const difficultyOptions = ref([1, 2, 3, 4, 5])
    const topic = ref(null)
    const name = ref(null)
    const description = ref(null)
    const duration = ref(null)
    const difficulty = ref(1)
    const total_items = ref(null)
    
    EventBus.on("addAssessmentDialog",(val) => {
        alert.value = val.status
        courseId.value = val.courseId
        getModuleList (val.courseId)
    })

    function getModuleList (courseId) {
        fetchingDropdown.value = true
        axios.get(process.env.VUE_APP_API_URL + '/module/dropdown',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {courseId: courseId}
        }).then((res) => {
          moduleList.value = res.data
          fetchingDropdown.value = false
        })
    }


    function getTopicList()  {

      fetchingModule.value = true
      axios.get(process.env.VUE_APP_API_URL + '/topics/dropdown',{
        headers: { 'Authorization': `Bearer ${token.value}`},
        params: {courseId: courseId.value,moduleId:moduleId.value}
      }).then((res) => {
        topicList.value = res.data
        fetchingModule.value = false
      })
    }


    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      fetchingDropdown,
      fetchingModule,
      moduleList,
      courseId,
      moduleId,
      topicList,
      difficultyOptions,
      topic,
      name,
      description,
      duration,
      difficulty,
      total_items,
      getTopicList,

      onSubmitAssessment () {
        var formData = {
          "courseId":courseId.value.trim(),
          "moduleId":moduleId.value.trim(),
          "topic_id":topic.value.trim(),
          "name":name.value.trim(),
          "description": description.value.trim(),
          "difficulty": difficulty.value,
          "duration": duration.value,
          "total_items": total_items.value.trim()
        }

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/assessment',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          EventBus.emit("refreshAssessment")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }

    }
  },

}
</script>