<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Update Course</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onUpdateCourse">
                <div class="q-pa-md">
                  <q-input color="indigo-10" outlined label="Course"
                    lazy-rules width="48" v-model="editCourse" :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input color="indigo-10" outlined
                    label="Description" lazy-rules v-model="editDescription" :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-file color="indigo-10" outlined label="Cover Photo" lazy-rules v-model="editFile">
                    <template v-slot:prepend>
                      <q-icon name="attach_file" />
                    </template>
                  </q-file>
                  <br/>
                  <div>
                    <q-btn style="background-color: #010127;"
                      color="white-8" rounded class="full-width q-mr-sm"
                      v-ripple size="lg" clickable :loading="isSubmitted"
                      type="submit">Update Course
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)
    const editId = ref(null)
    const editCourse = ref(null)
    const editDescription = ref(null)
    const editFile = ref(null)

    EventBus.on("editCourseModal",(val) => {
        alert.value = val.status
        editId.value = val.obj.id
        editCourse.value = val.obj.name
        editDescription.value = val.obj.description
    })

    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      editId,
      editCourse,
      editDescription,
      editFile,

      onUpdateCourse () {
        let formData = new FormData();
        formData.append("id", editId.value);
        formData.append("name", editCourse.value.trim());
        formData.append("description", editDescription.value.trim());
        formData.append("file", editFile.value);

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/course/update',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          EventBus.emit("refreshCourseData")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {

          console.log(e.response.data)

           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }
    }

  },

}
</script>