<template>
  <q-dialog v-model="alert" persistent transition-show="flip-down" transition-hide="flip-up" :backdrop-filter="backdropFilter">
      <q-card class="my-card" style="height: auto; width: 400px !important; max-width: 400px;">
        <q-card-section class="text-center text-bold q-mb-none q-pb-none q-pl-none q-pr-none">
          <div class="full-width col-12 q-mt-md q-mb-lg">
            <q-item-section v-show="confirm">
              <q-item-label>Do you want to end your session?</q-item-label>
            </q-item-section>
            <q-item-section v-show="confirmedOut">
              <q-item-label>
                <q-spinner-hourglass class="on-left" />Logging out...
              </q-item-label>
            </q-item-section>
          </div>
          <div class="q-ma-none q-pa-none">
            <q-list class="row">
              <q-item clickable class="bg-grey-8 text-white col" v-close-popup>
                <q-item-section>
                  <q-item-label>NO</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable class="bg-red-5 text-white col" @click="onSubmit">
                <q-item-section>
                  <q-item-label>YES</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </div>
        </q-card-section>
      </q-card>
  </q-dialog>
</template>
<script>

import { ref } from 'vue'
import vueCookies from 'vue-cookies';
import EventBus from "@/EventBus"

export default {

  setup () {
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const confirm = ref(true)
    const confirmedOut = ref(false)

    EventBus.on("logoutDialog",(val) => {
        alert.value = val.status
    })



    return {
      alert,
      confirm,
      confirmedOut,
      backdropFilter,

      onSubmit: function() {
        confirm.value = false;
        confirmedOut.value = true;
        vueCookies.remove(process.env.VUE_APP_API_KEY);
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    }
  },
}
</script>
<style lang="css" scoped>
</style>