<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Update Collection</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="onSubmit">
                <div class="q-pa-md">
                  <q-select outlined v-model="course" :options="coursesList" option-value="id" option-label="name" emit-value map-options label="Course" lazy-rules :rules="[ val => val && val.length > 0 || 'Please select options']"/>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Collection"
                    lazy-rules
                     v-model="name"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Description"
                    lazy-rules
                    v-model="description"
                    :rules="[ val => val && val.length > 0 || 'Please type something']">
                  </q-input>
                  <div>
                    <q-btn
                      style="background-color: #010127;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg"
                      clickable
                      :loading="isSubmitted"
                      type="submit"
                      >Update Collection
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)
    const coursesList = ref([])
    const id = ref(null)
    const course = ref(null)
    const name = ref(null)
    const description = ref(null)

    EventBus.on("updateModal",(val) => {
        alert.value = val.status
        id.value = val.obj.id
        course.value = val.obj.course
        name.value = val.obj.name
        description.value = val.obj.description
    })

    function getCoursesList ()  {
        axios.get(process.env.VUE_APP_API_URL + '/course/dropdown',{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((res) => {
          coursesList.value = res.data
        })
    }

    onMounted(() => {
      getCoursesList ()
    })

    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      coursesList,
      course,
      id,
      name,
      description,
      getCoursesList,

      onSubmit () {
        var formData = {
          "id":id.value.trim(),
          "course_id":course.value.trim(),
          "name":name.value.trim(),
          "description": description.value.trim()
        }

        isSubmitted.value = true; 
        axios.put(process.env.VUE_APP_API_URL + '/collection',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          EventBus.emit("refreshData")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }

    }
  },

}
</script>