<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="dialog"
      persistent
      :maximized="maximizedToggle">
      <q-card>
        <q-bar>
          <q-space />
          <q-btn dense flat icon="minimize" @click="maximizedToggle = false" :disable="!maximizedToggle">
            <q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="crop_square" @click="maximizedToggle = true" :disable="maximizedToggle">
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>
        <q-card-section>
          <div class="text-h6">{{ title }}</div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <q-list v-show="fetchingQuestion"> 
            <q-item-section v-for="n in 5" :key="n">
              <q-item-label>
                <q-skeleton type="text" height="150px"/>
              </q-item-label>
            </q-item-section> 
          </q-list>
          <q-list v-show="!fetchingQuestion" bordered separator>
            <q-item v-for="question in moduleQuestions" :key="question.id" class="q-my-sm">
              <q-item-section>
                <q-item-label caption>{{ question.max_answer }}</q-item-label>
                <q-item-label>{{ question.question }}</q-item-label>
              </q-item-section>

              <q-item-section side>
                <div class="q-pa-md q-gutter-sm">
                <q-btn size="sm" outline round color="primary" icon="list" @click="selectedRowChoices(question.choices)"/>
                <q-btn size="sm" outline round color="negative" icon="delete" @click="deleteQuestionBankModal(question.id)"/>
                </div>
              </q-item-section>
            </q-item>
          </q-list>

          <div class="q-pa-lg flex flex-center" v-show="!fetchingQuestion">
            <span>Showing {{ showingFrom }} to {{ showingTo }} of {{ totalEntries }} entries</span>  
            <q-space />
            <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="keyboard_double_arrow_left" @click="selectPagination(firstPageUrl)"/>
            <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="arrow_back_ios" @click="selectPagination(prevPageUrl)"/>
            <span>{{ firstPage }} / {{ lastPage }}</span>
            <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="arrow_forward_ios" @click="selectPagination(nextPageUrl)"/>
            <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="keyboard_double_arrow_right" @click="selectPagination(lastPageUrl)"/>
          </div>

          <QuestionChoices/>
          <DeleteQuestion/>
        </q-card-section>  
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import QuestionChoices from "./QuestionChoices.vue";
import DeleteQuestion from "./DeleteQuestion.vue";

import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"


export default {
  components: {
    QuestionChoices,
    DeleteQuestion
  },

  setup() {
    const dialog = ref(false)
    const maximizedToggle = ref(true)
    const title = ref(null)
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const moduleQuestions = ref([])
    const fetchingQuestion = ref(false)

    const showingFrom = ref(0)
    const showingTo = ref(0)
    const totalEntries = ref(0)

    const firstPage = ref(0)
    const lastPage = ref(0)

    const nextPageUrl = ref(null)
    const prevPageUrl = ref(null)

    const firstPageUrl = ref(null)
    const lastPageUrl = ref(null)

    EventBus.on("selectedModuleQuestion",(val) => {
        dialog.value = val.status
        title.value = val.obj.bank_name
        getModuleQuestions(1)

    })

    EventBus.on("refreshModuleBankQuestion",() => {
        getModuleQuestions(1)
    })

    const selectedRowChoices = (obj) => {
      EventBus.emit("selectedRowChoices",{status:true,choices:obj})
    };

    const selectPagination = (urls) => {
      
      const urlString = urls 
      const url = new URL(urlString);
      const params = new URLSearchParams(url.search);
      const page = params.get('page'); 
      getModuleQuestions(page)

    };

    function getModuleQuestions(page) {
        fetchingQuestion.value = true
        axios.get(process.env.VUE_APP_API_URL + '/question/module',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
                bank_name: title.value,
                page: page,
             }
        }).then((res) => {

          firstPage.value = res.data.current_page
          lastPage.value = res.data.last_page

          showingFrom.value = res.data.from
          showingTo.value = res.data.to
          totalEntries.value = res.data.total

          nextPageUrl.value = res.data.next_page_url
          prevPageUrl.value = res.data.prev_page_url

          firstPageUrl.value = res.data.first_page_url
          lastPageUrl.value = res.data.last_page_url

          moduleQuestions.value = res.data.data
          fetchingQuestion.value = false
        })
    }

    const updateModal = (obj) => {
      EventBus.emit("updateModal",{status:true,obj:obj})
    };

    const deleteQuestionBankModal = (id) => {
      EventBus.emit("deleteQuestionBankModal",{status:true,id:id})
    };


    return {
      dialog,
      maximizedToggle,
      title,
      token,
      fetchingQuestion,
      updateModal,
      deleteQuestionBankModal,
      selectedRowChoices,
      moduleQuestions,
      getModuleQuestions,

      showingFrom,
      showingTo,
      totalEntries,
      firstPageUrl,
      firstPage,
      nextPageUrl,
      prevPageUrl,
      lastPage,
      lastPageUrl,

      selectPagination
    }
  }
}
</script>


<style lang="sass">
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px

  .row + .row
    margin-top: 1rem
</style>
