<template>
  <q-layout id="bodyLogin">
    <div class="row q-pa-md">
      <div class="col q-pa-md">
        <div class="q-pa-md">
          <q-card class="my-card shadow-24 fixed-center q-pa-md">
            <q-item>
              <q-space></q-space>
              <q-avatar size="100px">
                <img src="@/assets/logo.png"/>
              </q-avatar>
              <q-space></q-space>
            </q-item>
            <div class="text-h4 text-weight-bold text-center">
              <span class="text-negative">Tofu </span>Digital
            </div>
            <q-card-section>
              <q-form @submit.prevent="onSubmit">
                <div class="q-gutter-sm">
                  <q-input
                    color="indigo-10"
                    outlined
                    v-model="username"
                    label="Username"
                    lazy-rules
                    :rules="[ val => val && val.length > 0 || 'Please type something']"
                  >
                    <template v-slot:append>
                      <q-icon name="person_outline" />
                    </template>
                  </q-input>
                  <q-input
                    :type="isPwd ? 'password' : 'text'"
                    color="indigo-10"
                    outlined
                    v-model="password"
                    label="Password"
                    lazy-rules
                    :rules="[ val => val && val.length > 0 || 'Please type something']"
                  >
                    <template v-slot:append>
                      <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                      />
                    </template>
                  </q-input>
                  <div>
                    <q-btn
                      style="background-color: #051d40;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      clickable
                      type="submit"
                      size="lg"
                      :loading="isSubmitted"
                      >Login
                    </q-btn>
                    <div class="text-center q-mt-md">
                      Dont have account?
                      <span class="text-primary" style="cursor: default">Register</span>
                    </div>
                  </div>
                </div>
              </q-form>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
  </q-layout>
</template>

<script >
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import vueCookies from 'vue-cookies';

export default {

  setup () {
    const $q = useQuasar()
    const username = ref(null)
    const password = ref(null)
    const isPwd = ref(true)
    const isSubmitted = ref(false)
    return {
      username,
      password,
      isPwd,
      isSubmitted,
   
      onSubmit () {

        let formData = new FormData();
        formData.append("email", username.value.trim());
        formData.append("password", password.value.trim());
        formData.append("role",'admin');
        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/login',formData).then((response) => {
          if(response.data.status){
            vueCookies.set(process.env.VUE_APP_API_KEY,response.data);
            setTimeout(() => {
              location.reload();
              isSubmitted.value = false
            }, 500)
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Submitted'
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      },
    };
  },
};
</script>

<style scoped>
.my-card {
  width: 100%;
  max-width: 400px;
}

#bodyLogin {
  background-image: url("https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  /*background-image: url("https://images.unsplash.com/photo-1510070112810-d4e9a46d9e91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80");*/
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
