<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Upload Question
            <br/>
             <q-btn label="Download Template" :loading="downloading" @click="downloadTemplate" size="sm" outline style="color: #010127;"/>
          </div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="uploadQuestion">
                <div class="q-pa-md">
                  <q-input outlined v-model="bankName" label="Group Name" lazy-rules :rules="[ val => val && val.length > 0 || 'This field is required']"/>
                  <q-file accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" color="indigo-10" outlined label="Attached File" lazy-rules v-model="excel_file" >
                    <template v-slot:prepend>
                      <q-icon name="attach_file" />
                    </template>
                  </q-file>
                  <br/>
                  <q-btn
                    style="background-color: #010127;"
                    color="white-8"
                    rounded
                    class="full-width q-mr-sm"
                    v-ripple
                    clickable
                    size="lg"
                    :loading="isSubmitted"
                    type="submit"
                    >Upload Question
                  </q-btn>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>

import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import EventBus from "@/EventBus"
import axios from 'axios'

export default {

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const isSubmitted = ref(false)
    const downloading = ref(false)
    const alert = ref(false)
    const bankName = ref(null)
    const excel_file = ref(null)

     EventBus.on("uploadQuestionModal",(val) => {
        alert.value = val.status
    })

    function downloadTemplate(){
      downloading.value = true
      axios.get(process.env.VUE_APP_API_URL + '/question/template',{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
            window.open(response.data.data,'_blank');
            downloading.value = false
        })

      }

    return {
      token,
      alert,
      bankName,
      downloading,
      downloadTemplate,
      backdropFilter,
      isSubmitted,
      excel_file,

      uploadQuestion () {
        let formData = new FormData();
        formData.append("bank_name", bankName.value.trim());
        formData.append("excel_file", excel_file.value);
        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/questionbank/upload',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          EventBus.emit("refreshQuesitonBankData")
          }else{
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           alert.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }
    }

  },
}
</script>