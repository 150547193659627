<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="dialog"
      persistent
      :maximized="maximizedToggle">
      <q-card>
        <q-bar>
          <q-space />
          <q-btn dense flat icon="minimize" @click="maximizedToggle = false" :disable="!maximizedToggle">
            <q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="crop_square" @click="maximizedToggle = true" :disable="maximizedToggle">
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <div class="text-h6">{{ title }}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
			<div>
				<q-splitter v-model="splitterModel">
				<template v-slot:before>
					<q-tabs v-model="tab" vertical class="text-teal">
          <q-tab name="modules" icon="view_module" label="Modules" />
					<q-tab name="topics" icon="description" label="Topics" />
					<q-tab name="videos" icon="play_lesson" label="Videos" />
					<q-tab name="notes" icon="note" label="Notes" />
          <q-tab name="assessment" icon="fact_check" label="Assessment" />
					</q-tabs>
				</template>

				<template v-slot:after>
					<q-tab-panels v-model="tab" animated swipeable vertical >
					<q-tab-panel name="modules">
						<Modules :courseId="id"/>
					</q-tab-panel>
          <q-tab-panel name="topics">
            <Topics :courseId="id"/>
          </q-tab-panel>
					<q-tab-panel name="videos">
						<Videos :courseId="id"/>
					</q-tab-panel>
					<q-tab-panel name="notes">
						<NotesViewCard :courseId="id"/>
					</q-tab-panel>
          <q-tab-panel name="assessment">
            <Assessment :courseId="id"/>
          </q-tab-panel>
					</q-tab-panels>
					</template>
				</q-splitter>
			</div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Modules from "../modules/CourseModule.vue";
import Topics from "../topics/TopicsView.vue";
import Videos from "../videos/VideosView.vue";
import NotesViewCard from "../notes/NotesViewCard.vue";
import Assessment from "../assessments/AssessmentsView.vue";
import { ref } from 'vue'
import EventBus from "@/EventBus"

export default {
  components: {
    Modules,
    Topics,
    Videos,
    NotesViewCard,
    Assessment
  },
  setup () {
	const dialog = ref(false)
	const maximizedToggle = ref(true)
	const id = ref(null)
	const title = ref(null)

	EventBus.on("courseDetails",(val) => {
        dialog.value = val.status
        id.value = val.course.id
        title.value = val.course.name
    })

    return {
      dialog,
      maximizedToggle,
      id,
      title,
      tab: ref('modules'),
      splitterModel: ref(10)
    }
  }
}
</script>
