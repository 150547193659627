<template>
  

  <div className="q-pa-md example-row-all-breakpoints">

      <div className="col-lg-12">
        <p className="text-h6 text-weight-bold">Manage Notes
          <q-btn style="background-color: #010127;" class="text-white float-right" icon="add_circle" label="New Notes" @click="openNotesModal()"/>
        </p>
      </div>
      <div class="row q-gutter-sm">

       <div v-show="noteList.length == 0 && !isLoading">
        <p class="text-body1 absolute-center">No Notes Available</p>
       </div>
     
      <q-card v-show="noteList.length > 0 && !isLoading" class="my-card" v-for="note in noteList" :key="note.id">
          <q-img src="@/assets/pdf.png" :ratio="16/9" fit="contain" loading="lazy" spinner-color="white">
          </q-img>
          <q-card-section>
            <div class="text-overline text-orange-9">{{ note.title }}</div>
            <div class="text-caption text-grey text-justify">{{ note.description }}</div>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat round color="green" icon="pageview" @click="pdfView(note.file)">
              <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                 <strong>View</strong>
               </q-tooltip>
            </q-btn>
            <q-btn flat round color="warning" icon="edit" @click="updateNotesModal(note)">
              <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                 <strong>Edit</strong>
               </q-tooltip>
            </q-btn>
            <q-btn flat round color="negative" icon="delete" @click="deleteNotesModal(note.id)">
              <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                 <strong>Delete</strong>
               </q-tooltip>
            </q-btn>
          </q-card-actions>
       </q-card>
      </div>
      <div class="q-pa-lg flex flex-center" v-show="!isLoading">
        <span>Showing {{ showingFrom }} to {{ showingTo }} of {{ totalEntries }} entries</span>  
        <q-space />
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="keyboard_double_arrow_left" @click="selectPagination(firstPageUrl)"/>
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="arrow_back_ios" @click="selectPagination(prevPageUrl)"/>
        <span>{{ firstPage }} / {{ lastPage }}</span>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="arrow_forward_ios" @click="selectPagination(nextPageUrl)"/>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="keyboard_double_arrow_right" @click="selectPagination(lastPageUrl)"/>
      </div>
      <AddNewNotes/>
      <ViewPdf/>
      <UpdateNote/>
      <DeleteNotes/>
  </div>
</template>

<script>
import AddNewNotes from "./AddNewNote.vue";
import ViewPdf from "./PdfViewer.vue";
import UpdateNote from "./UpdateNote.vue";
import DeleteNotes from "./DeleteNotes.vue";

import { useQuasar, QSpinnerPie } from 'quasar'
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  components: {
    AddNewNotes,
    ViewPdf,
    UpdateNote,
    DeleteNotes
  },

  props:{
    courseId:{
      type:String,
      required:true
    }
  },

  setup (props) {
    const $q = useQuasar()
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const isLoading = ref(false)
    const noteList = ref([])

    const showingFrom = ref(0)
    const showingTo = ref(0)
    const totalEntries = ref(0)

    const firstPage = ref(0)
    const lastPage = ref(0)

    const nextPageUrl = ref(null)
    const prevPageUrl = ref(null)

    const firstPageUrl = ref(null)
    const lastPageUrl = ref(null)

    EventBus.on("refreshNotes",() => getNotedList())

    const openNotesModal = () => {
      EventBus.emit("openDialog",{status:true,courseId:props.courseId})
    };

    const pdfView = (url) => {
      EventBus.emit("pdfView",{status:true,url:url})
    };

    const updateNotesModal = (obj) => {
      EventBus.emit("updateNotesModal",{status:true,obj:obj})
    };

    const deleteNotesModal = (id) => {
      EventBus.emit("deleteNotesModal",{status:true,id:id})
    };

    const selectPagination = (urls) => {
      
      const urlString = urls 
      const url = new URL(urlString);
      const params = new URLSearchParams(url.search);
      const page = params.get('page'); 
      getNotedList(page)

    };

    function getNotedList (page)  {
        isLoading.value = true
        $q.loading.show({
          spinner: QSpinnerPie,
          spinnerColor: 'red',
          messageColor: 'black',
          backgroundColor: 'grey',
          message: 'Fetching Records.<br><span class="text-italic">Please wait...</span>',
          html: true
        })

        axios.get(process.env.VUE_APP_API_URL + '/notes',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
              courseId:props.courseId,
              page: page,
           }
        }).then((res) => {
          firstPage.value = res.data.current_page
          lastPage.value = res.data.last_page

          showingFrom.value = res.data.from
          showingTo.value = res.data.to
          totalEntries.value = res.data.total

          nextPageUrl.value = res.data.next_page_url
          prevPageUrl.value = res.data.prev_page_url

          firstPageUrl.value = res.data.first_page_url
          lastPageUrl.value = res.data.last_page_url

          noteList.value = res.data.data

          isLoading.value = false
          $q.loading.hide()
          
        })
    }

    onMounted(() => {
      getNotedList()
    })


    return {
      token,
      isLoading,
      openNotesModal,
      pdfView,
      updateNotesModal,
      deleteNotesModal,
      noteList,

      showingFrom,
      showingTo,
      totalEntries,
      firstPageUrl,
      firstPage,
      nextPageUrl,
      prevPageUrl,
      lastPage,
      lastPageUrl,

      selectPagination
    }
  }
}
</script>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 300px
</style>

