<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Update Bank Name</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section >
              <q-form @submit.prevent="updateBankName">
                <div class="q-pa-md">
                  <q-input
                    color="indigo-10"
                    outlined
                    label="Bank Name"
                    lazy-rules
                    width="48"
                    v-model="newBankName"
                    :rules="[ val => val && val.length > 0 || 'Please type name']"
                  >
                    <template v-slot:append>
                      <q-icon name="help" />
                    </template>
                  </q-input>
                  <q-btn
                    style="background-color: #010127;"
                    color="white-8"
                    rounded
                    class="full-width q-mr-sm"
                    v-ripple
                    clickable
                    size="lg"
                    :loading="isSubmitted"
                    type="submit"
                    >Update Bank Name
                  </q-btn>
                </div>
              </q-form>
            </q-card-section>
      </q-card>
    </q-dialog>
</template>

<script>

import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import EventBus from "@/EventBus"
import axios from 'axios'

export default {

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const isSubmitted = ref(false)
    const alert = ref(false)
    const id = ref(null)
    const oldBankName = ref(null)
    const newBankName = ref(null)

     EventBus.on("editQuestionBank",(val) => {
        alert.value = val.status
        id.value = val.obj.id
        oldBankName.value = val.obj.bank_name
        newBankName.value = val.obj.bank_name
    })


    return {
      token,
      alert,
      backdropFilter,
      isSubmitted,
      id,
      oldBankName,
      newBankName,

      updateBankName() {
        var formData = {
          "new_name":newBankName.value.trim(),
          "old_name":oldBankName.value.trim(),
        }

        isSubmitted.value = true; 
        axios.put(process.env.VUE_APP_API_URL + '/question/bank-name',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            EventBus.emit("refreshQuestionBankData")
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          
          }else{
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           alert.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }
    }

  },
}
</script>