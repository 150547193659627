<template>
  <div class="q-pa-md example-row-all-breakpoints">
    <div class="row">
      <div class="col-lg-12">
        <p class="text-h6 text-weight-bold">Manage Course</p>
        <div  align="right" v-show="!loading">
          <q-btn style="background-color: #010127;" class="text-white" icon="add_circle" label="Add New Course" @click="openModal()"/>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <q-table
          :dense="$q.screen.lt.md"
          flat bordered
          ref="tableRef"
          title="Courses"
          :rows="rows"
          :columns="columns"
          row-key="id"
          v-model:pagination="pagination"
          :loading="loading"
          :filter="filter"
          binary-state-sort
          @request="onRequest">
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td key="name" :props="props">{{ props.row.name }}</q-td>
              <q-td key="description" :props="props">{{ props.row.description }}</q-td>
              <q-td key="created_by" :props="props">{{ props.row.created_by }}</q-td>
              <q-td key="date_upload" :props="props">{{ props.row.date_upload }}</q-td>
              <q-td key="status" :props="props">
                <q-chip outline :color="props.row.status ? 'positive' : 'negative'" text-color="white" :icon="props.row.status ? 'check_circle' : 'cancel'">
                {{ props.row.status ? 'Active' : 'Inactive' }}
              </q-chip>
              </q-td>
              <q-td key="date_created" :props="props">{{ props.row.date_created }}</q-td>
              <q-td key="action" :props="props">
                <q-btn size="sm" round outline color="warning" @click="updateModal(props.row)">
                    <i class="fas fa-edit"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Edit</strong>
                    </q-tooltip>
                </q-btn>
                &nbsp;
                <q-btn size="sm" round outline color="negative" @click="deleteModal(props.row.id)">
                    <i class="fas fa-trash"></i>
                    <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                      <strong>Inactive</strong>
                    </q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
          </template>
          <template v-slot:top-right>
            <q-input dense debounce="300" v-model="filter" placeholder="Search">
            </q-input>
          </template>
          <template v-slot:no-data="{ icon, message, filter }">
            <div class="full-width row flex-center text-accent q-gutter-sm">
              <q-icon style="color: #010127;" size="2em" name="sentiment_dissatisfied" />
              <span style="color: #010127;">{{ message }}</span>
              <q-icon style="color: #010127;" size="2em" :name="filter ? 'filter_b_and_w' : icon" />
            </div>
          </template>
        </q-table>
      </div>
    </div>
    <AddNewCourse/>
    <UpdateCourse/>
    <DeleteCourse/>
  </div>
</template>

<script>
import AddNewCourse from "./AddNewCourse.vue";
import UpdateCourse from "./UpdateCourse.vue";
import DeleteCourse from "./DeleteCourse.vue";
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

const columns = [
  { name: 'name', align: 'left', label: 'Course', field: 'name', sortable: true },
  { name: 'description', align: 'left',label: 'Description', field: 'description', sortable: true },
  { name: 'created_by', align: 'left', label: 'Created By', field: 'created_by', sortable: true },
  { name: 'date_upload', align: 'left', label: 'Date Created', field: 'date_upload', sortable: true },
  { name: 'status', align: 'left', label: 'Status', field: 'status', sortable: true },
  { name: 'action', align: 'center', label: 'Action', field: '', sortable: true },
]

export default {
  components: {
    AddNewCourse,
    UpdateCourse,
    DeleteCourse,
  },

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const tableRef = ref()
    const rows = ref([])
    const filter = ref('')
    const loading = ref(false)
    const pagination = ref({
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10
    })

    EventBus.on("refreshCourseData",() => tableRef.value.requestServerInteraction())

    const openModal = () => {
      EventBus.emit("openModal",{status:true})
    };

    const updateModal = (obj) => {
      EventBus.emit("updateModal",{status:true,obj:obj})
    };

    const deleteModal = (id) => {
      EventBus.emit("deleteModal",{status:true,id:id})
    };

    async function fetchFromServer (startRow, count, filter) {
        let response =  await axios.get(process.env.VUE_APP_API_URL + '/course', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                start: startRow,
                countPerPage: count,
                filter: filter,
             }
         })
        return response.data
    }

    async function getRowsNumberCount  (filter)  {

        let response =  await axios.get(process.env.VUE_APP_API_URL + '/course/total', {
             headers: { 'Authorization': `Bearer ${token.value}`},
             params: {
                filter: filter,
             }
         })

      return response.data
    }

    async function onRequest (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination
      const filter = props.filter
       loading.value = true

        await getRowsNumberCount(filter).then(
          function(value) { pagination.value.rowsNumber = value},
        )

        const fetchCount = rowsPerPage === 0 ? pagination.value.rowsNumber : rowsPerPage
        const startRow = (page - 1) * rowsPerPage

        await fetchFromServer(startRow, fetchCount, filter, sortBy, descending).then(
          function(value) { rows.value.splice(0, rows.value.length, ...value), loading.value = false},
        )

        pagination.value.page = page
        pagination.value.rowsPerPage = rowsPerPage
        pagination.value.sortBy = sortBy
        pagination.value.descending = descending
    }

    onMounted(() => {
      tableRef.value.requestServerInteraction()
    })

    return {
      token,
      tableRef,
      filter,
      loading,
      pagination,
      columns,
      rows,
      openModal,
      updateModal,
      deleteModal,
      onRequest
    }
  }
}
</script>

<style lang="sass">
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px
  .row + .row
    margin-top: 1rem
</style>
