<template>
  <q-dialog persistent  v-model="alert" :backdrop-filter="backdropFilter">
      <q-card style="width: 500px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Get Question from Bank</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
              <q-form @submit.prevent="addQuestion">
                <div class="q-pa-md">
                  <q-select outlined v-model="bankName" :loading="fetchingDropdown" :options="bankList" option-value="name" option-label="total" emit-value map-options label="Get from what bank" lazy-rules :rules="[ val => val && val.length > 0 || 'Please select options']"/>
                  <q-input type="number" min="1" color="indigo-10" outlined label="Get how many items" lazy-rules v-model="total_items" :rules="[ val => val && val.length > 0 || 'Please type something']"/>
                  <div>
                    <q-btn
                      v-show="totalQuestion < totalItems"
                      style="background-color: #010127;"
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg"
                      clickable
                      :loading="isSubmitted"
                      type="submit"
                      >Add Question
                    </q-btn>

                    <q-btn
                      v-show="totalQuestion >= totalItems"
                      style="background-color: #010127;"
                      disable 
                      color="white-8"
                      rounded
                      class="full-width q-mr-sm"
                      v-ripple
                      size="lg">Question is Full
                    </q-btn>
                  </div>
                </div>
              </q-form>
            </q-card-section>
            <q-markup-table v-show="!fetchingAssessment && assessmentTotalList.length > 0">
              <thead>
                <tr>
                  <th class="text-center">Source Bank</th>
                  <th class="text-center">Total Items</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in assessmentTotalList" :key="item">
                  <td class="text-center">{{ item.bank }}</td>
                  <td class="text-center">{{ item.total_items }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th class="text-center">Needed/Uploaded</th>
                  <th class="text-center">{{ totalItems }}/{{ totalQuestion }}</th>
                </tr>
              </tfoot>
            </q-markup-table>
      </q-card>
    </q-dialog>
</template>

<script>
import { useQuasar } from 'quasar'  
import { ref } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  
  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const $q = useQuasar()
    const backdropFilter = ref('grayscale(100%)')
    const alert = ref(false)
    const isSubmitted = ref(false)
    const fetchingDropdown = ref(false)
    const fetchingAssessment = ref(false)
    const bankList = ref([])
    const assessmentTotalList = ref([])
    const totalQuestion = ref(null)
    const assessmentId = ref(null)
    const totalItems = ref(null)
    const bankName = ref(null)
    const total_items = ref(null)
    
    EventBus.on("addQuestionDialog",(val) => {
        alert.value = val.status
        assessmentId.value = val.obj.id
        totalItems.value = val.obj.total_items
        getBankList() 
        getTotalQuestions(val.obj.id);
    })


    function getBankList (){
        fetchingDropdown .value = true
        axios.get(process.env.VUE_APP_API_URL + '/brank/dropdown',{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((res) => {
          bankList.value = res.data
          fetchingDropdown .value = false
        })
    }

    function getTotalQuestions (assessmentId){
        fetchingAssessment .value = true
        axios.get(process.env.VUE_APP_API_URL + '/assessment/question',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {assessmentId: assessmentId}
        }).then((res) => {
          assessmentTotalList.value = res.data.data
          totalQuestion.value = res.data.total
          fetchingAssessment .value = false
        })
    }



    return {
      token,
      alert,
      backdropFilter,
      fetchingDropdown,
      fetchingAssessment,
      isSubmitted,
      bankList,
      assessmentTotalList,
      totalQuestion,
      totalItems,
      bankName,
      total_items,
      
      addQuestion() {
        var formData = {
          "assessmentId":assessmentId.value.trim(),
          "bank_name":bankName.value.trim(),
          "total_need": total_items.value.trim()
        }

        isSubmitted.value = true; 
        axios.post(process.env.VUE_APP_API_URL + '/assessment/question',formData,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((response) => {
          if(response.data.status){
            isSubmitted.value = false
            alert.value = false
            $q.notify({
              color: 'positive',
              textColor: 'white',
              icon: 'check_circle',
              message: response.data.message
            })
          //EventBus.emit("refreshAssessment")
          }else{
            isSubmitted.value = false
            $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: response.data.message
            })
          }
        }).catch((e) => {
           isSubmitted.value = false
           $q.notify({
              color: 'negative',
              textColor: 'white',
              icon: 'warning',
              message: e.response.data.message
            })
        })
      }
    }
  },

}
</script>