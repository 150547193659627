<template>

   <div v-show="courseList.length == 0 && !isLoading" class="q-pa-md row items-start q-gutter-md">
    <p class="text-body1 absolute-center">No Course Available</p>
    </div>
   
    <div v-show="courseList.length > 0" class="q-pa-md row items-start q-gutter-md">
    
    <q-card v-show="!isLoading" class="my-card" v-for="course in courseList" :key="course.id">
      <q-img :src="course.thumbnail" 
      :ratio="16/9" fit="contain" loading="lazy" spinner-color="white">
        <div class="absolute-bottom">
          {{ course.name }}
        </div>
      </q-img>
      <q-card-section>
        {{ course.description  }}
      </q-card-section>
      <q-card-actions align="right">
        <q-btn  icon="more_vert" style="background-color: #010127;" color="white-8">
        <q-menu auto-close transition-show="jump-down" transition-hide="jump-up">
          <q-list style="min-width: 100px">
            <q-item clickable @click="courseDetails(course)">
              <q-item-section avatar>
                <q-icon color="primary" name="read_more" />
              </q-item-section>
              <q-item-section>See more</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable @click="editCourseModal(course)">
              <q-item-section avatar>
                <q-icon color="amber" name="edit" />
              </q-item-section>
              <q-item-section>Edit</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable @click="deleteCourseModal(course.id)">
              <q-item-section avatar>
                <q-icon color="negative" name="delete" />
              </q-item-section>
              <q-item-section>Delete</q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      </q-card-actions>
    </q-card>
    <CourseDetails/>
    <DeleteCourse/>
    <EditCourse/>
  </div>

</template>

<script>
import { useQuasar, QSpinnerPie } from 'quasar'
import CourseDetails from "../courses/CourseDetails.vue";
import DeleteCourse from "./DeleteCourse.vue";
import EditCourse from "./EditCourse.vue";
import { ref, onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  components: {
    CourseDetails,
    DeleteCourse,
    EditCourse,
  },

  setup() {
    const $q = useQuasar()
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const isLoading = ref(false)
    const startRow = ref(0)
    const countPerPage = ref(10)
    const filter = ref(null)
    const courseList = ref([])

    const courseDetails = (course) => {
      EventBus.emit("courseDetails",{status:true,course:course})
    };

    EventBus.on("refreshCourseData",() => getCourseList())

    const deleteCourseModal = (id) => {
      EventBus.emit("deleteCourseModal",{status:true,id:id})
    };

    const editCourseModal = (obj) => {
      EventBus.emit("editCourseModal",{status:true,obj:obj})
    };

    function getCourseList ()  {
        isLoading.value = true
        $q.loading.show({
          spinner: QSpinnerPie,
          spinnerColor: 'red',
          messageColor: 'black',
          backgroundColor: 'grey',
          message: 'Fetching Records.<br><span class="text-italic">Please wait...</span>',
          html: true
        })
        axios.get(process.env.VUE_APP_API_URL + '/course',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
              start: startRow.value,
              countPerPage: countPerPage.value,
              filter: filter.value,
           }
        }).then((res) => {
          isLoading.value = false
          $q.loading.hide()
          courseList.value = res.data
        }).catch(error => {
          console.log(error)
        });
    }

    onMounted(() => {
      getCourseList ()
    })

    return {
      token,
      isLoading,
      startRow,
      countPerPage,
      filter,
      courseList,
      deleteCourseModal,
      courseDetails,
      editCourseModal
    };
  },
};
</script>

<style lang="sass" scoped>
.example-row-all-breakpoints
  .row > div
    padding: 10px 15px
  .row + .row
    margin-top: 1rem

.my-card
  width: 100%
  max-width: 300px
  min-height: 320px

</style>
