<template>
  <div className="q-pa-md example-row-all-breakpoints">
      <div className="col-lg-12">
        <p className="text-h6 text-weight-bold">Videos
          <q-btn style="background-color: #010127;" class="text-white float-right" icon="add_circle" label="Add New Video" @click="openVideoModal(courseId)"/>
        </p>
      </div>
      <div class="row q-gutter-sm">
         <div v-show="videoList.length == 0 && !loading">
          <p class="text-body1 absolute-center">No Video Available</p>
         </div>

        <q-card class="my-card" v-show="videoList.length > 0 && !loading" v-for="video in videoList" :key="video.id">
          <video controls style="max-height: 100%;max-width: 100%;">
            <source :src="video.path_url" type="video/mp4">
          </video>
          <q-card-section>
            <div class="text-h6">{{ video.title }}</div>
            <div class="text-subtitle2">
              {{ video.date_upload }}
              <q-btn size="sm" class="float-right" flat round color="red" icon="delete" @click="deleteVideoModal(video.id)"/>
            </div>
          </q-card-section>

          <q-card-section class="q-pt-none">
            {{ video.description }}
          </q-card-section>
        </q-card>
      </div>
      <div class="q-pa-lg flex flex-center" v-show="!loading">
        <span>Showing {{ showingFrom }} to {{ showingTo }} of {{ totalEntries }} entries</span>  
        <q-space />
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="keyboard_double_arrow_left" @click="selectPagination(firstPageUrl)"/>
        <q-btn flat round color="primary" :disable="prevPageUrl == null" icon="arrow_back_ios" @click="selectPagination(prevPageUrl)"/>
        <span>{{ firstPage }} / {{ lastPage }}</span>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="arrow_forward_ios" @click="selectPagination(nextPageUrl)"/>
        <q-btn flat round color="primary" :disable="nextPageUrl == null" icon="keyboard_double_arrow_right" @click="selectPagination(lastPageUrl)"/>
      </div>
    <AddNewVideo/>
    <DeleteVideo/>
  </div>
</template>

<script>
import { useQuasar, QSpinnerPie } from 'quasar'
import AddNewVideo from "./UploadVideo.vue";
import DeleteVideo from "./DeleteVideo.vue";
import { ref,onMounted } from 'vue'
import axios from 'axios'
import EventBus from "@/EventBus"

export default {
  components: {
    AddNewVideo,
    DeleteVideo
  },

  props:{
    courseId:{
      type:String,
      required:true
    }
  },

  setup (props) {
    const $q = useQuasar()
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const loading = ref(false)
    const videoList = ref([])

    const showingFrom = ref(0)
    const showingTo = ref(0)
    const totalEntries = ref(0)

    const firstPage = ref(0)
    const lastPage = ref(0)

    const nextPageUrl = ref(null)
    const prevPageUrl = ref(null)

    const firstPageUrl = ref(null)
    const lastPageUrl = ref(null)

    EventBus.on("refreshVideo",() => getVideoList())

    const openVideoModal = (courseId) => {
      EventBus.emit("openVideoModal",{status:true,courseId:courseId})
    };

    const deleteVideoModal = (id) => {
      EventBus.emit("deleteVideoModal",{status:true,id:id})
    };

    const selectPagination = (urls) => {
      
      const urlString = urls 
      const url = new URL(urlString);
      const params = new URLSearchParams(url.search);
      const page = params.get('page'); 
      getVideoList(page)

    };

    function getVideoList (page)  {
        loading.value = true
        $q.loading.show({
          spinner: QSpinnerPie,
          spinnerColor: 'red',
          messageColor: 'black',
          backgroundColor: 'grey',
          message: 'Fetching Records.<br><span class="text-italic">Please wait...</span>',
          html: true
        })

        axios.get(process.env.VUE_APP_API_URL + '/videos',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
              courseId:props.courseId,
              page: page,
           }
        }).then((res) => {

          firstPage.value = res.data.current_page
          lastPage.value = res.data.last_page

          showingFrom.value = res.data.from
          showingTo.value = res.data.to
          totalEntries.value = res.data.total

          nextPageUrl.value = res.data.next_page_url
          prevPageUrl.value = res.data.prev_page_url

          firstPageUrl.value = res.data.first_page_url
          lastPageUrl.value = res.data.last_page_url

          videoList.value = res.data.data
          
          $q.loading.hide()
          loading.value = false
          
        })
    }

    onMounted(() => {
      getVideoList ()
    })


    return {
      token,
      loading,
      openVideoModal,
      deleteVideoModal,
      videoList,

      showingFrom,
      showingTo,
      totalEntries,
      firstPageUrl,
      firstPage,
      nextPageUrl,
      prevPageUrl,
      lastPage,
      lastPageUrl,

      selectPagination

    }
  }
}
</script>
<style lang="sass" scoped>
.my-card
  width: 100%
  max-width: 300px
</style>

